










































































































import Vue from 'vue'
import {
  PropValidator,
} from 'vue/types/options'
import {
  DataTableHeader,
} from 'vuetify'
import {
  lessonStore,
} from '@/store'
import {
  newChapter,
} from '@/util/chapter'
import {
  Chapter, ChapterLesson,
} from '@/types/chapter'
import {
  Lesson,
} from '@/types/lesson'
import {
  Id,
} from '@/types/base'
import {
  newLesson,
} from '@/util/lesson'
import LessonSelectorButton from '@/components/Chapter/LessonSelectorButton.vue'

export default Vue.extend({
  components: {
    LessonSelectorButton,
  },
  props: {
    chapter: {
      default: newChapter(),
      type: Object,
    } as PropValidator<Chapter>,
  },
  data: () => ({
    headers: [
      {
        text: `Lessons`,
        value: `lessonRows`,
        sortable: false,
      },
    ] as DataTableHeader[],
    chapter_: newChapter(),
  }),
  computed: {
    lessons (): (Lesson & Id)[] {
      return lessonStore.data
    },
    isLoadingLessonGetAll (): boolean {
      return lessonStore.isLoadingGetAll
    },
    chapterLessonIds () {
      return this.chapter_.lessonRows.flatMap((row) => row.lessons.map((l) => l.id))
    },
  },
  watch: {
    chapter: {
      deep: true,
      immediate: true,
      handler (chapter: (Chapter & Id)) {
        this.chapter_ = chapter
      },
    },
    chapter_: {
      deep: true,
      handler (chapter: (Chapter & Id)) {
        this.$emit(`update:chapter`, chapter)
      },
    },
    chapterLessonIds (ids: string[]) {
      this.loadLessonList(ids)
    },
  },
  methods: {
    async moveLessonIdsUp (index: number) {
      const {
        lessonRows,
      } = this.chapter_
      const lessonIds = lessonRows.splice(index, 1)[0]
      lessonRows.splice(index - 1, 0, lessonIds)
    },
    async moveLessonIdsDown (index: number) {
      const {
        lessonRows,
      } = this.chapter_
      const lessonIds = lessonRows.splice(index, 1)[0]
      lessonRows.splice(index + 1, 0, lessonIds)
    },
    removeChapterLesson (lessons: ChapterLesson[], lessonIndex: number) {
      lessons.splice(lessonIndex, 1)
    },
    getLessonById (id: string): (Lesson & Id) {
      return lessonStore.dataById(id) || {
        id,
        ...newLesson(),
      }
    },
    loadLessonList (ids: string[]) {
      lessonStore.loadList(ids)
    },
  },
})
