













































































import Vue from 'vue'
import {
  lessonStore,
} from '@/store'
import {
  newLesson,
  getLessonColor,
  getLessonIcon,
} from '@/util/lesson'
import RequiredLessonIndicator from './RequiredLessonIndicator.vue'
import {
  PropValidator,
} from 'vue/types/options'
import LessonSelectorDialog from './LessonSelectorDialog.vue'
import {
  ChapterLesson,
} from '@/types/chapter'
import {
  newChapterLesson,
} from '@/util/chapter'

export default Vue.extend({
  components: {
    RequiredLessonIndicator,
    LessonSelectorDialog,
  },
  props: {
    chapterLesson: {
      default: () => newChapterLesson(),
      type: Object,
    } as PropValidator<ChapterLesson>,
    previousLessonIds: {
      default: () => [
      ],
      type: Array,
    } as PropValidator<string[]>,
    takenLessonIds: {
      default: () => [
      ],
      type: Array,
    } as PropValidator<string[]>,
  },
  data: () => ({
    dialog: false,
    chapterLesson_: newChapterLesson(),
  }),
  computed: {
    lesson () {
      return lessonStore.dataById(this.chapterLesson.id) ?? {
        ...newLesson(),
        id: ``,
      }
    },
    availableLessonIds () {
      return lessonStore.data
        .map((l) => l.id)
        .filter((id) => !this.takenLessonIds.includes(id))
    },
  },
  watch: {
    chapterLesson: {
      immediate: true,
      deep: true,
      handler (chapterLesson: ChapterLesson) {
        this.chapterLesson_ = chapterLesson
      },
    },
    chapterLesson_: {
      deep: true,
      handler (chapterLesson: ChapterLesson, oldChapterLesson: ChapterLesson) {
        if (!chapterLesson.id) return
        if (!oldChapterLesson.id) {
          this.$emit(`add:chapterLesson`, chapterLesson)
          this.chapterLesson_ = newChapterLesson()
        } else this.$emit(`update:chapterLesson`, chapterLesson)
      },
    },
  },
  methods: {
    getLessonColor,
    getLessonIcon,
    getLessonById (id: string) {
      return lessonStore.dataById(id) ?? {
        ...newLesson(),
        id: ``,
      }
    },
    updateRequiredLessonId (lessonId: string, oldLessonId: string) {
      const index = this.chapterLesson_.requiredLessonIds.findIndex((id) => id === oldLessonId)
      this.chapterLesson_.requiredLessonIds.splice(index, 1, lessonId)
    },
    removeRequiredLessonId (lessonId: string) {
      const index = this.chapterLesson_.requiredLessonIds.findIndex((id) => id === lessonId)
      this.chapterLesson_.requiredLessonIds.splice(index, 1)
    },
  },
})
