























































import mixins from 'vue-typed-mixins'
import {
  vModel,
} from '@/mixins'
import {
  newLesson,
} from '@/util/lesson'
import {
  PropValidator,
} from 'vue/types/options'
import {
  lessonStore,
} from '@/store'
import {
  Lesson,
} from '@/types/lesson'
import {
  Id,
} from '@/types/base'

export default mixins(vModel)
  .extend({
    props: {
      lesson: {
        default: () => ({
          ...newLesson(),
          id: ``,
        }),
        type: Object,
      } as PropValidator<Lesson & Id>,
      availableLessonIds: {
        default: () => [
        ],
        type: Array,
      } as PropValidator<string[]>,
    },
    data: () => ({
      selectedLessonId: ``,
    }),
    computed: {
      availableLessonIds_ () {
        const lessonIds = [
          ...this.availableLessonIds,
        ]
        if (this.lesson.id) lessonIds.unshift(this.lesson.id)
        return lessonIds
      },
    },
    watch: {
      value_ (value: boolean) {
        this.selectedLessonId = value ? this.lesson.id : ``
      },
    },
    methods: {
      setLesson () {
        if (this.selectedLessonId) this.$emit(`update:lesson`, lessonStore.dataById(this.selectedLessonId))
        this.value_ = false
      },
      removeLesson () {
        this.$emit(`remove:lessonId`, this.lesson.id)
        this.value_ = false
      },
      getLessonById (id: string) {
        return lessonStore.dataById(id) ?? {
          ...newLesson(),
          id: ``,
        }
      },
    },
  })
