import {
  Chapter, ChapterLesson,
} from '@/types/chapter'

export const newChapter = (): Chapter => ({
  name: ``,
  translation: ``,
  lessonRows: [
    {
      lessons: [
      ],
    },
  ],
})

export const newChapterLesson = (): ChapterLesson => ({
  id: ``,
  requiredLessonIds: [
  ],
})
