


































import {
  lessonStore,
} from '@/store'
import {
  Id,
} from '@/types/base'
import {
  Lesson,
} from '@/types/lesson'
import {
  newLesson,
  getLessonColor,
  getLessonIcon,
} from '@/util/lesson'
import Vue from 'vue'
import {
  PropValidator,
} from 'vue/types/options'
import RequiredLessonSelectorDialog from '@/components/Chapter/RequiredLessonSelectorDialog.vue'

export default Vue.extend({
  components: {
    RequiredLessonSelectorDialog,
  },
  props: {
    lessonId: {
      type: String,
      default: ``,
    },
    takenLessonIds: {
      type: Array,
      default: () => [
      ],
    } as PropValidator<string[]>,
    previousLessonIds: {
      type: Array,
      default: () => [
      ],
    } as PropValidator<string[]>,
  },
  data: () => ({
    dialog: false,
    lesson_: {
      ...newLesson(),
      id: ``,
    },
  }),
  computed: {
    lesson (): Lesson & Id {
      return lessonStore.dataById(this.lessonId) ?? {
        ...newLesson(),
        id: ``,
      }
    },
    availableLessonIds () {
      return this.previousLessonIds.filter((id) => !this.takenLessonIds.includes(id))
    },
  },
  watch: {
    lesson: {
      immediate: true,
      deep: true,
      handler (lesson: (Lesson & Id)) {
        this.lesson_ = lesson
      },
    },
    lesson_: {
      deep: true,
      handler (lesson: (Lesson & Id), oldLesson: (Lesson & Id)) {
        if (!lesson.id) return
        if (!oldLesson.id) {
          this.$emit(`add:lessonId`, lesson.id)
          this.lesson_ = {
            ...newLesson(),
            id: ``,
          }
        } else this.$emit(`update:lessonId`, lesson.id)
      },
    },
  },
  methods: {
    getLessonColor,
    getLessonIcon,
  },
})
