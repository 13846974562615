























































import mixins from 'vue-typed-mixins'
import {
  vModel,
} from '@/mixins'
import {
  newLesson,
} from '@/util/lesson'
import {
  PropValidator,
} from 'vue/types/options'
import {
  lessonStore,
} from '@/store'
import {
  newChapterLesson,
} from '@/util/chapter'
import {
  ChapterLesson,
} from '@/types/chapter'

export default mixins(vModel)
  .extend({
    props: {
      chapterLesson: {
        default: () => newChapterLesson(),
        type: Object,
      } as PropValidator<ChapterLesson>,
      availableLessonIds: {
        default: () => [
        ],
        type: Array,
      } as PropValidator<string[]>,
    },
    data: () => ({
      selectedLessonId: ``,
    }),
    computed: {
      availableLessonIds_ () {
        const lessonIds = [
          ...this.availableLessonIds,
        ]
        if (this.chapterLesson.id) lessonIds.unshift(this.chapterLesson.id)
        return lessonIds
      },
    },
    watch: {
      value_ (value: boolean) {
        this.selectedLessonId = value ? this.chapterLesson.id : ``
      },
    },
    methods: {
      setLesson () {
        const newChapterLesson: ChapterLesson = {
          id: this.selectedLessonId,
          requiredLessonIds: [
          ],
        }
        if (this.selectedLessonId) this.$emit(`update:chapterLesson`, newChapterLesson)
        this.value_ = false
      },
      removeLesson () {
        this.$emit(`remove:chapterLesson`, this.chapterLesson)
        this.value_ = false
      },
      getLessonById (id: string) {
        return lessonStore.dataById(id) ?? {
          ...newLesson(),
          id: ``,
        }
      },
    },
  })
